<template>
  <div>
    <v-row align="center">
      <v-col>
        <h1>Roles & Permissions</h1>
      </v-col>
      <v-col cols="8">
        <v-row align="center" justify="end" no-gutters>
          <v-btn depressed color="accent" @click="createDialog.open = true">
            <v-icon left>mdi-plus</v-icon>Add Role
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs v-model="tab.id" vertical v-on:change="tabChanged()">
        <v-tab
          v-for="role in roles"
          v-bind:key="role.id"
          class="justify-start"
          >{{ role.name }}</v-tab
        >
        <v-tab-item v-for="role in roles" v-bind:key="role.id">
          <v-card flat>
            <v-card-text>
              <div v-if="!tab.editing">
                <v-row no-gutters>
                  <v-col class="subtitle-1"
                    >{{ role.name }}'s Permissions</v-col
                  >
                  <v-col>
                    <v-row align="center" justify="end" no-gutters>
                      <v-btn
                        small
                        depressed
                        color="info"
                        class="mr-2"
                        @click="startEditing(role)"
                      >
                        <v-icon left>mdi-pencil</v-icon>Edit
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        color="warning"
                        class="mr-2"
                        @click="cloneRole(role)"
                      >
                        <v-icon left>mdi-content-copy</v-icon>Clone
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        color="error"
                        @click="deleteRole(role)"
                      >
                        <v-icon left>mdi-delete</v-icon>Delete
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-3 mb-3"></v-divider>
                <ul v-if="role.permissions.length > 0">
                  <li
                    v-for="permission in role.permissions"
                    v-bind:key="permission.id"
                  >
                    {{ permission.display_name }}
                  </li>
                </ul>
                <v-alert type="info" v-else outlined dense
                  >This role has no permissions</v-alert
                >
              </div>
              <div v-else>
                <v-row no-gutters>
                  <v-col class="subtitle-1"
                    >Edit {{ role.name }}'s Permissions</v-col
                  >
                  <v-col>
                    <v-row align="center" justify="end" no-gutters>
                      <v-btn
                        small
                        depressed
                        color="default"
                        class="mr-2"
                        @click="selectToggle()"
                      >
                        <v-icon left>{{
                          rolePermissions.allSelected
                            ? "mdi-checkbox-marked"
                            : "mdi-checkbox-blank-outline"
                        }}</v-icon>
                        {{
                          rolePermissions.allSelected ? "Unselect" : "Select"
                        }}
                        All
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        color="success"
                        class="mr-2"
                        :loading="saving"
                        @click="updateRole()"
                      >
                        <v-icon left>mdi-content-save</v-icon>Save
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        color="error"
                        @click="cancelEditing()"
                      >
                        <v-icon left>mdi-close</v-icon>Cancel
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-3 mb-3"></v-divider>
                <v-list dense>
                  <v-list-item
                    v-for="permission in permissions"
                    v-bind:key="permission.id"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        color="primary"
                        v-model="rolePermissions.permissions"
                        :value="permission.id"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        permission.display_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog v-model="createDialog.open" max-width="400px">
      <v-card>
        <v-form @submit.prevent="createRole" method="post">
          <v-card-title class="headline">Create New Role</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Name *"
              type="text"
              v-model="createDialog.name"
              outlined
              dense
              :error="createDialog.errors.hasOwnProperty('name')"
              :error-messages="createDialog.errors['name']"
            ></v-text-field>
            <p class="subtitle mb-0">Permission(s)</p>
            <v-list dense>
              <v-list-item
                v-for="permission in permissions"
                v-bind:key="permission.id"
              >
                <v-list-item-action>
                  <v-checkbox
                    color="primary"
                    v-model="createDialog.permissions"
                    :value="permission.id"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    permission.display_name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="closeCreate()">Close</v-btn>
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="createDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cloneDialog.open" max-width="400px">
      <v-card>
        <v-form @submit.prevent="saveClone" method="post">
          <v-card-title class="headline"
            >Clone {{ cloneDialog.roleName }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Name *"
              type="text"
              v-model="cloneDialog.newRoleName"
              outlined
              dense
              :error="cloneDialog.errors.hasOwnProperty('name')"
              :error-messages="cloneDialog.errors['name']"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="closeClone()">Close</v-btn>
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="cloneDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteRoleDialog.open" max-width="400px">
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteRoleDialog.roleName }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert type="error" dense v-if="deleteRoleDialog.error"
            >There was an error when attempting to delete this role. Please make
            sure there aren't any users attached to this role and try
            again</v-alert
          >Are you sure you want to delete this role?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDeleteRole()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDeleteRole()"
            :loading="deleteRoleDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: {
        id: null,
        editing: false,
      },
      rolePermissions: {
        roleId: null,
        permissions: [],
        allSelected: false,
      },
      saving: false,
      createDialog: {
        open: false,
        name: null,
        permissions: [],
        allSelected: false,
        errors: {},
        loading: false,
      },
      cloneDialog: {
        open: false,
        roleName: "",
        roleId: null,
        newRoleName: null,
        errors: {},
        loading: false,
      },
      deleteRoleDialog: {
        open: false,
        error: false,
        roleName: "",
        roleId: null,
        loading: false,
      },
    };
  },

  computed: {
    roles() {
      return this.$store.state.users["appRoles"];
    },
    permissions() {
      return this.$store.state.users["appPermissions"];
    },
  },

  methods: {
    tabChanged: function() {
      this.tab.editing = false;
      this.rolePermissions.roleId = null;
      this.rolePermissions.permissions = [];
      this.rolePermissions.allSelected = false;
    },
    startEditing: function(role) {
      this.tab.editing = true;
      this.rolePermissions.roleId = role.id;
      this.rolePermissions.permissions = role.permissions.map(p => p.id);
    },
    cancelEditing: function() {
      this.tab.editing = false;
      this.rolePermissions.roleId = null;
      this.rolePermissions.permissions = [];
      this.rolePermissions.allSelected = false;
    },
    selectToggle: function() {
      if (this.rolePermissions.allSelected) {
        this.rolePermissions.permissions = [];
      } else {
        this.rolePermissions.permissions = this.permissions.map(p => p.id);
      }

      this.rolePermissions.allSelected = !this.rolePermissions.allSelected;
    },
    updateRole: function() {
      const roleId = this.rolePermissions.roleId;
      const permissions = this.rolePermissions.permissions;
      const appId = this.$route.params.id;
      this.saving = true;

      this.$store
        .dispatch("users/updateRolePermissions", { appId, permissions, roleId })
        .then(() => {
          this.tab.editing = false;
          this.rolePermissions.roleId = null;
          this.rolePermissions.permissions = [];
          this.rolePermissions.allSelected = false;
          this.saving = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Permissions Successfully Saved to Role",
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Error when attempting to Save Permission to Role",
          });
        });
    },
    cloneRole: function(role) {
      this.cloneDialog.roleName = role.name;
      this.cloneDialog.roleId = role.id;
      this.cloneDialog.open = true;
    },
    closeClone: function() {
      this.cloneDialog = {
        open: false,
        roleName: "",
        roleId: null,
        newRoleName: null,
        errors: {},
        loading: false,
      };
    },
    saveClone: function() {
      const roleId = this.cloneDialog.roleId;
      const appId = this.$route.params.id;
      const name = this.cloneDialog.newRoleName;

      this.cloneDialog.loading = true;

      this.$store
        .dispatch("users/cloneRole", { appId, roleId, name })
        .then(() => {
          this.cloneDialog = {
            open: false,
            roleName: "",
            roleId: null,
            newRoleName: null,
            errors: {},
            loading: false,
          };
        })
        .catch(err => {
          this.cloneDialog.errors = err.response.data.errors;
          this.cloneDialog.loading = false;
        });
    },
    deleteRole: function(role) {
      this.deleteRoleDialog.open = true;
      this.deleteRoleDialog.roleName = role.name;
      this.deleteRoleDialog.roleId = role.id;
    },
    closeDeleteRole: function() {
      this.deleteRoleDialog = {
        open: false,
        roleName: "",
        roleId: null,
        error: false,
        loading: false,
      };
    },
    confirmDeleteRole: function() {
      const roleId = this.deleteRoleDialog.roleId;
      const appId = this.$route.params.id;
      this.deleteRoleDialog.loading = true;

      this.$store
        .dispatch("users/deleteRole", { appId, roleId })
        .then(() => {
          this.deleteRoleDialog = {
            open: false,
            roleName: "",
            roleId: null,
            error: false,
            loading: false,
          };
        })
        .catch(() => {
          this.deleteRoleDialog.loading = false;
          this.deleteRoleDialog.error = true;
        });
    },
    createRole: function() {
      const appId = this.$route.params.id;
      const name = this.createDialog.name;
      const permissions = this.createDialog.permissions;
      this.createDialog.loading = true;

      this.$store
        .dispatch("users/createRole", { appId, name, permissions })
        .then(() => {
          this.createDialog = {
            open: false,
            name: null,
            permissions: [],
            allSelected: false,
            errors: {},
            loading: false,
          };
        })
        .catch(err => {
          this.createDialog.errors = err.response.data.errors;
          this.createDialog.loading = false;
        });
    },
    closeCreate: function() {
      this.createDialog = {
        open: false,
        name: null,
        permissions: [],
        allSelected: false,
        errors: {},
        loading: false,
      };
    },
  },
};
</script>
